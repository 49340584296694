import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default {
  state: {
    IsLoggedIn: false,
  },
  getters: {
    GetIsLoggedIn(state) {
      if (localStorage.getItem("isLoggedIn_SB") == "true") {
        state.IsLoggedIn = true;
      }
      return state.IsLoggedIn;
    },
  },
  actions: {
    async Login({ commit }, payload) {
      return await axios
        .post(`${process.env.VUE_APP_API_URl}Admin/AdminLogin`, payload)
        .then((res) => {
          commit("setLogin", res.data.status);
          return res.data;
        });
    },
    async Logout({ commit }, payload) {
      commit("Logout");
    },
  },
  mutations: {
    setLogin(state, payload) {
      if (payload) {
        localStorage.setItem("isLoggedIn_SB", payload);
      }
      state.IsLoggedIn = payload;
    },
    Logout(state, payload) {
      state.IsLoggedIn = false;
      localStorage.setItem("isLoggedIn_SB", false);
    },
  },
};
