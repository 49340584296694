import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default {
  state: {
    Youths: [],
  },
  getters: {
    GetYouths(state) {
      return state.Youths;
    },
  },
  actions: {
    
    async GetYouths({ commit }, payload) {
      return await axios
        .get(
          `${process.env.VUE_APP_API_URl}Organization/GetAllAppliedOpportunity/Search?page=${payload.page}&pageSize=${payload.pageSize}&searchString=${payload.search}`
        )
        .then((res) => {
          console.log(res.data.data.recordOnPage)
          commit("SetYouths", res.data.data.recordOnPage);
          return res.data.data;
        });
    },
    async PayYouth({commit},payload){
      return await axios
      .post(`${process.env.VUE_APP_API_URl}Payment/PayYouthById/${payload}`)
      .then((res)=>{
        return res.data;
      });
    },
    async DeleteYouth({ commit }, payload) {
      return await axios
        .delete(
          `${process.env.VUE_APP_API_URl}Opportunity/DeleteYouthById/${payload}`
        )
        .then((res) => {
          return res.data;
        });
    },
  },
  mutations: {
    SetYouths(state, payload) {
      state.Youths = payload;
    },
  },
};
