import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default {
  state: {
    Users: [],
  },
  getters: {
    GetUsers(state) {
      return state.Users;
    },
  },
  actions: {
    async GetUsers({ commit }, payload) {
      return await axios
        .get(
          `${process.env.VUE_APP_API_URl}Admin/GetAllUser/Data?page=${payload.page}&pageSize=${payload.pageSize}&searchString=${payload.search}`
        )
        .then((res) => {
          commit("SetUsers", res.data.data.recordOnPage);
          return res.data.data;
        });
    },
    async deleteUser({ commit }, payload) {
      return await axios
        .delete(`${process.env.VUE_APP_API_URl}User/DeleteUser/${payload}`)
        .then((res) => {
          return res.data;
        });
    },
  },
  mutations: {
    SetUsers(state, payload) {
      state.Users = payload;
    },
  },
};
