import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("../views/Main/DashboardLayout.vue"),
    children: [
      {
        path: "/",
        component: () => import("../views/users/users.vue"),
      },
      {
        path: "/user",
        component: () => import("../views/users/users.vue"),
      },
      {
        path: "/opportunity",
        component: () => import("../views/Opportunity/Opportunity.vue"),
      },
      {
        path: "/youth",
        component: () => import("../views/Youth/Youth.vue"),
      },
      {
        path: "/busniess",
        component: () => import("../views/Business/Business.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("../views/Layout/authlayout/authLayout.vue"),
    redirect: "/login",
    children: [
      {
        path: "/login",
        name: "login",
        component: () => import("../views/Auth/Login.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (store.getters["GetIsLoggedIn"]) {
    if (to.name == "login") {
      next({ path: "/" });
    } else {
      next();
    }
  } else {
    if (to.name != "login") {
      next({ path: "/login" });
    } else {
      next();
    }
  }
});
export default router;
