import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default {
  state: {
    Organizations: [],
  },
  getters: {
    GetOrganizations(state) {
      return state.Organizations;
    },
  },
  actions: {
    async GetOrganizations({ commit }, payload) {
      return await axios
        .get(
          `${process.env.VUE_APP_API_URl}Admin/GetAllOrganization/Search?page=${payload.page}&pageSize=${payload.pageSize}&searchString=${payload.search}`
        )
        .then((res) => {
          commit("SetOrganizations", res.data.data.recordOnPage);
          return res.data.data;
        });
    },
    async DeleteOrganization({ commit }, payload) {
      return await axios
        .delete(
          `${process.env.VUE_APP_API_URl}Organization/DeleteOrganizationById/${payload}`
        )
        .then((res) => {
          return res.data;
        });
    },
    async UpdateSubscriptionStatus({ commit }, payload) {
      return await axios
        .patch(
          `${process.env.VUE_APP_API_URl}Organization/UpdateSubscriptionStatus?organizationId=${payload}`
        )
        .then((res) => {
          return res.data;
        });
    },
  },
  mutations: {
    SetOrganizations(state, payload) {
      state.Organizations = payload;
    },
  },
};
