import Vue from "vue";
import Vuex from "vuex";
import Users from "./Users";
import Organizations from "./Organizations";
import Opportunity from "./Opportunity";
import Youth from "./Youth";
import Auth from "./Auth";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: { Users, Organizations, Opportunity, Youth, Auth },
});
